import { z } from 'zod';

// eslint-disable-next-line import/prefer-default-export
export const smsSendModalSchema = z.object({
  text: z.string().min(1, 'Поле является обязательным').max(1000, 'Поле должно содержать не более 1000 символов'),
  formattedNumber: z.string().refine((value) => value.length === 12,
    'Некорректный номер телефона'),
});
export const smsSendModalUnlimitedSchema = z.object({
  text: z.string().min(1, 'Поле является обязательным'),
  formattedNumber: z.string().refine((value) => value.length === 12,
    'Некорректный номер телефона'),
});
