<template>
  <b-modal
    visible
    scrollable
    centered
    size="lg"
    title="Новое СМС-сообщение"
    :hide-footer="isSaving"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isSaving">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-form-group
        class="mb-0"
        label="Номер телефона"
        label-class="required"
        invalid-feedback="Поле является обязательным"
        :state="!errors['formattedNumber']"
      >
        <b-form-input
          v-model="number"
          v-mask="'+7(###) ###-##-##'"
          size="sm"
          placeholder="+7(___) ___-__-__"
          required
          @input="validateField('formattedNumber', number)"
        />
      </b-form-group>

      <b-form-group
        :invalid-feedback="textError"
        :state="!errors['text']"
      >
        <div class="d-flex align-items-center my-1">
          <label class="col-form-label required">
            Текст сообщения
          </label>

          <b-button
            v-if="!agreedServices"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="ml-auto"
            size="sm"
            @click="openSmsTemplatesModal"
          >
            Шаблоны
          </b-button>
        </div>

        <b-form-textarea
          v-model="text"
          placeholder="Введите текст"
          :rows="10"
          required
          @input="onTextInput"
        />
        <small v-if="!agreedServices">
          Осталось символов {{ availableCharacters }}/{{ maxTextLength }}
        </small>
      </b-form-group>
    </template>

    <template #modal-footer>
      <b-button
        variant="success"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClickSave"
      >
        Отправить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Bus from '@/eventBus';
import { validationMixin } from 'vuelidate';
import { showValidationErrorMessage } from '@/helpers/messages';
// eslint-disable-next-line import/no-extraneous-dependencies
import { mask } from 'vue-the-mask';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

import Preloader from '@/components/Preloader';
import { makeValidate, validateData } from '@/validation/zod/main/helper.validation';
import { smsSendModalSchema, smsSendModalUnlimitedSchema } from '@/validation/zod/sms/smsSendModal.validation';

export default {
  name: 'SmsSendModal',
  components: {
    Preloader,
  },
  directives: { mask },
  mixins: [validationMixin],
  props: {
    numberProp: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    agreedServices: {
      type: String,
      default: null,
    },
    notAgreedServices: {
      type: String,
      default: null,
    },
    clinic: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      templateSearchString: '',
      templates: [],
      isOpen: true,
      isSaving: false,
      number: '',
      text: '',
      maxTextLength: 1000,
      fieldsToValidate: ['text', 'formattedNumber'],
      errors: [],
    };
  },
  computed: {
    formattedNumber() {
      return this.phoneFormatToSave(this.number);
    },
    filteredTemplates() {
      return this.templates.filter((t) => {
        if (this.templateSearchString && !t.text.toLowerCase().includes(this.templateSearchString.toLowerCase())) return false; // search by template from templatesSearchString

        return true;
      });
    },
    availableCharacters() {
      return Math.max(0, this.maxTextLength - this.text.length);
    },
    textError() {
      return this.errors.text ? this.errors.text[0] : null;
    },
    validationSchema() {
      return this.agreedServices ? smsSendModalUnlimitedSchema : smsSendModalSchema;
    },
  },
  mounted() {
    if (this.numberProp) this.number = this.numberProp;
    if (this.agreedServices) {
      this.text = 'Добрый день!\n'
        + `В рамках полиса ДМС Вам были согласованы услуги: ${this.agreedServices}\n\n`;

      if (this.notAgreedServices) {
        this.text += `К сожалению, было отказано в: ${this.notAgreedServices}\n\n`;
      }

      this.text += `Гарантийное письмо на согласованные услуги направлено в клинику ${this.clinic}\n`
        + 'По всем вопросам: 8-800-500-02-99.\n'
        + 'Ваш Доктис.';
    } else {
      const messageLocalStorage = localStorage.getItem('send-modal-message');
      if (messageLocalStorage && typeof JSON.parse(messageLocalStorage) !== 'object') localStorage.removeItem('send-modal-message');

      const messagesObject = messageLocalStorage ? JSON.parse(messageLocalStorage) : {};
      const message = messagesObject[this.numberProp];
      if (message) {
        try {
          this.text = message;
        } catch (e) {
          this.text = `Добрый день${this.name ? `, ${this.name}` : ''}!\nПо всем вопросам: 8-800-500-02-99.\nВаш Доктис.`;
        }
      } else {
        this.text = `Добрый день${this.name ? `, ${this.name}` : ''}!\nПо всем вопросам: 8-800-500-02-99.\nВаш Доктис.`;
      }

      this.fetchSmsTemplates();
    }
  },
  methods: {
    validateField(field, value) {
      const error = validateData(field, value, this.validationSchema);
      if (error) {
        this.$set(this.errors, field, error);
      } else {
        this.$delete(this.errors, field);
      }
    },

    makeValidate(fields) {
      const errors = makeValidate(fields, this, this.validationSchema);
      this.errors = [];
      Object.keys(errors).forEach((field) => {
        this.$set(this.errors, field, errors[field]);
      });
    },

    async openSmsTemplatesModal() {
      try {
        this.templates = await this.$store.dispatch(this.$types.SMS_TEMPLATES_FETCH);
      } catch (err) {
        console.log('Error with download sms templates', err);
      }
      uiService.showModal(MODALS.SMS_TEMPLATES_MODAL, {
        name: 'SmsTemplatesModal',
        props: {
          title: 'Выберите шаблон для вставки его в текст смс-сообщения',
          agreedServices: this.agreedServices,
          allTemplates: this.templates,
        },
        actions: {
          selectTemplate: (value) => {
            this.text = value;
            this.onTextInput(value);
          },
        },
      });
    },
    async fetchSmsTemplates() {
      this.templates = await this.$store.dispatch(this.$types.SMS_TEMPLATES_FETCH);
    },
    phoneFormatToSave(phone) {
      if (phone) {
        return phone
          .split(' ')
          .join('')
          .split('(')
          .join('')
          .split('_')
          .join('')
          .split(')')
          .join('')
          .split('-')
          .join('');
      }
      return '';
    },
    onClose() {
      this.$emit('input', false);
    },
    onTextInput(value) {
      this.validateField('text', this.text);
      const messageLocalStorage = localStorage.getItem('send-modal-message');
      const messagesObject = messageLocalStorage ? JSON.parse(messageLocalStorage) : {};
      messagesObject[this.numberProp] = value;
      localStorage.setItem('send-modal-message', JSON.stringify(messagesObject));
    },
    async onClickSave() {
      this.makeValidate(this.fieldsToValidate);
      if (Object.keys(this.errors).length > 0) {
        showValidationErrorMessage();
        return;
      }

      this.isSaving = true;

      try {
        await this.$store.dispatch(this.$types.MESSAGES_SEND_MESSAGE, {
          number: this.phoneFormatToSave(this.number),
          text: this.text,
        });

        const messageLocalStorage = localStorage.getItem('send-modal-message');
        const messagesObject = messageLocalStorage ? JSON.parse(messageLocalStorage) : {};
        delete messagesObject[this.numberProp];
        localStorage.setItem('send-modal-message', JSON.stringify(messagesObject));

        Bus.$emit('messages:update');
        this.onClose();
      } catch (error) {
        console.error(error);
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-cell-overflow {
  max-width: 1px;
}
::v-deep(.modal-body) {
  padding: 10px 20px;
}
.form-group {
  margin-bottom: 0;
}
::v-deep(.modal-footer) {
  padding: 2px 12px;
}
::v-deep(.custom-dropdown) {
 .dropdown-menu {
    min-width: 151px;

    &.show {
      display: flex;
    }
 }
}

.template-item {
  position: relative;

  &:not(:first-child) {
    margin-top: 1.2rem;
  }

  &__title {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;

    &::before {
      position: absolute;
      content: '';
      transform: translate(0, -100%);
      left: 0;
      top: 0;
      height: 17px;
      width: 100%;
      background-color: #fff;
      z-index: -1;
    }
  }

  &__text {
    word-break: break-word;
    color: white;
    white-space: pre-wrap;
  }
}

.size-btns {
  width: 40px;
  height: 40px;
}

.icon-cursor {
  cursor: pointer;
}
</style>
